<template>
  <div class="login__form-title">
    Авторизация
  </div>
  <form class="login__form form" @submit.prevent>
    <label class="field field--icon field--dark login__field" :class="{ 'v-error': form.errors.email }">
      <span class="visually-hidden">Введите email</span>
      <input
        v-model="form.body.email"
        class="field__input"
        type="text"
        placeholder="Email"
        :disabled="isSubmitting"
      >
      <span v-if="form.errors.email" class="form__error active">{{ form.errors.email }}</span>
    </label>
    <label class="field field--dark login__field" :class="{ 'v-error': form.errors.password }">
      <span class="visually-hidden">Введите пароль</span>
      <input
        v-model="form.body.password"
        class="field__input"
        type="password"
        placeholder="Пароль"
        :disabled="isSubmitting"
      >
      <span v-if="form.errors.password" class="form__error active">{{ form.errors.password }}</span>
    </label>
    <div class="login__row">
      <button :disabled="isSubmitting" class="btn btn--primary-light btn--large login__submit" @click.prevent="login">
        Войти
      </button>
      <router-link class="login__form-link" to="/password-recovery">
        Забыли пароль?
      </router-link>
    </div>
  </form>
  <div class="login__copy">
    <router-link class="login__copy-text" to="/policy">
      Политика конфиденциальности
    </router-link>
    <div class="login__signature">
      ©{{ currentYear }}, «DSF Trading Co»
    </div>
  </div>
</template>

<script>
import { useAuth } from '@websanova/vue-auth/src/v3.js'
import { reactive, ref } from 'vue'
import { catchFormError } from '@/common/catchFormError'
import { useToast } from 'vue-toastification'

export default {
  setup() {
    const form = reactive({
      body: {
        email: '',
        password: '',
      },
      errors: {}
    })
    const isSubmitting = ref(false)
    const currentYear = ref((new Date()).getFullYear())
    const auth = useAuth()
    const toast = useToast()
    const login = () => {
      isSubmitting.value = true
      form.body.email = form.body.email.toLowerCase()
      auth.login({
        data: form.body
      }).catch(catchFormError(form, toast)).finally(() => {
        isSubmitting.value = false
      })
    }
    return {
      isSubmitting,
      form,
      login,
      currentYear
    }
  }
}
</script>
